import React from "react"
import { Link } from "gatsby"
import { Nav } from "react-bootstrap"
import NavDropdown from "react-bootstrap/NavDropdown"

import "./menu.css"

export default ({ toggle }) => (
  <div className="navigation">
    <Nav className="flex-column">
      <Nav.Item>
        <Link to="/">Home</Link>
      </Nav.Item>
      <Nav.Item>
        <Link onClick={toggle} to="/#about">
          About
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link onClick={toggle} to="/#services">
          Services
        </Link>
      </Nav.Item>
      {/* <Nav.Item>
        <Link onClick={toggle} to="/#footer">
          Certifications
        </Link>
      </Nav.Item> */}
      <Nav.Item>
        <NavDropdown
          id="nav-dropdown-dark-example"
          className="py-0 my-0"
          title="Resources"
          menuVariant="dark"
        >
          <NavDropdown.Item>
            <Link to="/medbulletin" className="text-dark">
              Med Bulletin
            </Link>
          </NavDropdown.Item>
          <NavDropdown.Item>
            <Link to="/sample-submission-guidelines" className="text-dark">
              {" "}
              Sample Submission Guidelines
            </Link>
          </NavDropdown.Item>
          <NavDropdown.Item>
            <Link to="/sample-size-guidelines" className="text-dark">
              {" "}
              Sample Size Guidelines
            </Link>
          </NavDropdown.Item>
        </NavDropdown>
      </Nav.Item>
      <Nav.Item>
        <Link onClick={toggle} to="#contact">
          Contact
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link onClick={toggle} to="/#downloads">
          Download & Data Access
        </Link>
      </Nav.Item>
      <Nav.Item>
        <a
          href="https://orders.confidentcannabis.com/#!/login?lab=bonafides"
          title="Confident Cannabis Login"
          target="_blank"
          rel="noreferrer noopener"
        >
          Confident Cannabis Login
        </a>
      </Nav.Item>
    </Nav>
  </div>
)
